import {DB_Value, DB_VariableRelation, DBProto_Expression, DBProto_Value, DBProto_Variable, DBProto_VariableRelation} from '@app/hcs';
import {ModuleFE_Value} from '@app/hcs/_entity/value/frontend';
import {ModuleFE_Variable} from '@app/hcs/_entity/variable/frontend';
import {LL_H_T, TS_PropRenderer} from '@nu-art/thunderstorm/frontend';
import * as React from 'react';
import {DBProto, generateHex} from '@nu-art/ts-common';
import {ModuleFE_Expression} from '@app/hcs/_entity/expression/frontend';
import {ModuleFE_DiseaseValue} from '@app/dp/_entity/disease-value/frontend';
import {ModuleFE_DiseaseProfile} from '@app/dp/_entity/disease-profile/frontend';
import {ModuleFE_MultiVar} from '@app/dp/_entity/multi-var/frontend';
import {ModuleFE_VariableRelation} from '@app/hcs/_entity/variable-relation/frontend';
import {DB_DiseaseValue, DBProto_DiseaseValue} from '@app/dp/_entity/disease-value/shared';
import {DBProto_DiseaseProfile} from '@app/dp/_entity/disease-profile/shared';
import {DBProto_Action} from '@app/pathway';
import {ModuleFE_Action} from '@app/pathway/_entity/action/frontend';
import {ModuleFE_AdvisorSnippet} from '@app/advisor-content/_entity/snippet/frontend';
import {DBProto_AdvisorSnippet} from '@app/advisor-content/_entity/snippet/shared';
import {ModuleFE_AdvisorWizard} from '@app/advisor-content/_entity/wizard/frontend';
import {DBProto_AdvisorWizard} from '@app/advisor-content/_entity/wizard/shared';

const getValueTextualView = (value: DBProto_Value['dbType']): JSX.Element => {
	if (!value)
		return <React.Fragment key={generateHex(3)}>Value Not Found</React.Fragment>;

	let variable;
	if (value.type === 'enum-element') {
		const parentValue = ModuleFE_Value.cache.find(_val => _val.__valueIds.includes(value._id));
		if (!parentValue)
			return <React.Fragment key={value._id}>Value Not Found</React.Fragment>;

		variable = ModuleFE_Variable.cache.find(variable => variable.valueId === parentValue._id);
	} else if (value.type === 'enumerated') {
		variable = ModuleFE_Variable.cache.find(variable => variable.valueId === value._id);
	}

	if (!variable)
		return <React.Fragment key={value._id}>Correlated Variable Not Found</React.Fragment>;

	return <LL_H_T className={'value-textual-row'} key={value._id}>
		<TS_PropRenderer.Vertical label={'Variable'}>
			{`${variable.name}, `}
		</TS_PropRenderer.Vertical>
		<TS_PropRenderer.Vertical label={'Value'}>
			{value.type === 'enum-element' ? (value as DB_Value<'enum-element'>).data.value : ''}
		</TS_PropRenderer.Vertical>
	</LL_H_T>;
};

const getDiseaseValueTextualView = (diseaseValue: DB_DiseaseValue) => {
	if (!diseaseValue)
		return <React.Fragment key={generateHex(3)}>Disease Value Not Found</React.Fragment>;

	const diseaseProfile = ModuleFE_DiseaseProfile.cache.unique(diseaseValue._diseaseProfileId);
	if (!diseaseProfile) //A DiseaseValue is meaningless without a Disease Profile
		return <React.Fragment key={diseaseValue._id}>Correlated Disease Profile Not Found</React.Fragment>;

	const multivar = ModuleFE_MultiVar.cache.unique(diseaseValue.multiVarId);
	if (!multivar) //A DiseaseValue is meaningless without a Variable
		return <React.Fragment key={diseaseValue._id}>Correlated MultiVar Not Found</React.Fragment>;

	const variable = ModuleFE_Variable.cache.unique(multivar?._firstVarId);
	if (!variable)
		return <React.Fragment key={diseaseValue._id}>Correlated Variable Not Found</React.Fragment>;

	return <LL_H_T className={'diseasevalue-textual-row'}>
		<TS_PropRenderer.Vertical label={'Disease Profile'}>
			{`${diseaseProfile.label}, `}
		</TS_PropRenderer.Vertical>
		<TS_PropRenderer.Vertical label={'MultiVar'}>
			{`${variable.name}, `}
		</TS_PropRenderer.Vertical>
		<TS_PropRenderer.Vertical label={'Value'}>
			{''}
		</TS_PropRenderer.Vertical>
	</LL_H_T>;
};

const getVariableRelationTextualView = (variableRelation: DB_VariableRelation) => {
	if (!variableRelation)
		return <React.Fragment key={generateHex(3)}>Not Found</React.Fragment>;

	const baseVariable = ModuleFE_Variable.cache.unique(variableRelation._firstVariableId);
	const comparedVariables = variableRelation.rightSide.map(item =>
		ModuleFE_Variable.cache.unique(item.variableId));

	return <React.Fragment key={variableRelation._id}>{`${baseVariable?.name} ${variableRelation.relation} ${comparedVariables.map((currVar, index) =>
		currVar!.name + (index !== comparedVariables.length - 1 ? ',' : ''))}`}</React.Fragment>;
};

/**
 * A map with dbKey for key, and function receives dbItem and returns jsx for value.
 */
export type ProtoTypedMap<Proto extends DBProto<any> = DBProto<any>> = {
	[Key in Proto['dbKey']]: (item: Extract<Proto, { dbKey: Key }>['dbType']) => JSX.Element;
};

export const CollectionItemRenderers: ProtoTypedMap<DBProto_Value | DBProto_Variable | DBProto_Expression | DBProto_DiseaseValue | DBProto_DiseaseProfile | DBProto_VariableRelation | DBProto_Action | DBProto_AdvisorSnippet | DBProto_AdvisorWizard> = {
	[ModuleFE_Value.dbDef.dbKey]: getValueTextualView,
	[ModuleFE_Variable.dbDef.dbKey]: (dbItem: DBProto_Variable['dbType']) => <TS_PropRenderer.Vertical label={'Variable'} key={dbItem._id}>{dbItem.name}</TS_PropRenderer.Vertical>,
	[ModuleFE_Expression.dbDef.dbKey]: (dbItem: DBProto_Expression['dbType']) =>
		<TS_PropRenderer.Vertical label={'Expression'} key={dbItem._id}>{dbItem.label}</TS_PropRenderer.Vertical>,
	[ModuleFE_DiseaseValue.dbDef.dbKey]: getDiseaseValueTextualView,
	[ModuleFE_DiseaseProfile.dbDef.dbKey]: (dbItem: DBProto_DiseaseProfile['dbType']) => <TS_PropRenderer.Vertical label={'Disease Profile'}
																												   key={dbItem._id}>{dbItem.label}</TS_PropRenderer.Vertical>,
	[ModuleFE_VariableRelation.dbDef.dbKey]: getVariableRelationTextualView,
	[ModuleFE_Action.dbDef.dbKey]: (dbItem: DBProto_Action['dbType']) => <TS_PropRenderer.Vertical label={'Order'} key={dbItem._id}>{dbItem.name}</TS_PropRenderer.Vertical>,
	[ModuleFE_AdvisorSnippet.dbDef.dbKey]: (dbItem: DBProto_AdvisorSnippet['dbType']) => <TS_PropRenderer.Vertical label={'Advisor Snippet'}
																												   key={dbItem._id}>{dbItem.label}</TS_PropRenderer.Vertical>,
	[ModuleFE_AdvisorWizard.dbDef.dbKey]: (dbItem: DBProto_AdvisorWizard['dbType']) => <TS_PropRenderer.Vertical label={'Advisor Wizard'}
																												 key={dbItem._id}>{dbItem.label}</TS_PropRenderer.Vertical>,
};
export const config = {
  "label": "staging",
  "ModuleFE_Thunderstorm": {
    "appName": "app-km-frontend - (staging)"
  },
  "ModuleFE_XHR": {
    "origin": "https://knowledge-manager-backend.staging.quai.md",
    "timeout": 30000,
    "compress": false,
    "minLogLevel": false
  },
  "ModuleFE_FirebaseListener": {
    "firebaseConfig": {
      "apiKey": "AIzaSyB5ikMwzGcBM3_HMb4saBpzeSH9Yq2Dmmw",
      "authDomain": "quai-md-km-staging.firebaseapp.com",
      "databaseURL": "https://quai-md-km-staging-default-rtdb.firebaseio.com",
      "projectId": "quai-md-km-staging",
      "storageBucket": "quai-md-km-staging.appspot.com",
      "messagingSenderId": "475711750997",
      "appId": "1:475711750997:web:2224754ffc262aee9de5dc",
      "measurementId": "G-KRQ0JB1ZX8"
    }
  }
};
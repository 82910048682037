import * as React from 'react';
import {
	_className,
	AwaitModules,
	ComponentSync,
	LL_H_C,
	ModuleFE_BaseApi,
	ModuleFE_BaseDB,
	ModuleFE_Dialog,
	ModuleFE_Notifications,
	ModuleFE_RoutingV2,
	ModuleFE_ServerInfo,
	ModuleFE_StorageCleaner,
	OnLocationChanged,
	OnServerInfoUpdatedListener,
	Thunder
} from '@nu-art/thunderstorm/frontend';
import {ICONS} from '@app/styles';
import {ModuleFE_AppModule, RouteDomainsMap, ToggleSideNavBarStateListener} from '@modules/app-module/ModuleFE_AppModule';
import {DBProto_Discussion, DispatcherType_Discussion, ModuleFE_Discussion} from '@app/hcs/frontend';
import {Component_DiscussionButton} from '@km/components/Component_Header/Component_DiscussionsButton/Component_DiscussionButton';
import {Component_AccountThumbnail, ModuleFE_Account, PopUp_AccountMenu, PopUp_AccountMenu_Action} from '@nu-art/user-account/frontend';
import {Component_UserDetailsEditor} from '@km/components/Component_Header/Component_UserDetailsEditor/Component_UserDetailsEditor';
import {UniqueId} from '@nu-art/ts-common';
import {ApiCallerEventType, DispatcherInterface} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';
import {AccessLevel, ModuleFE_PermissionsAssert} from '@nu-art/permissions/frontend/modules/ModuleFE_PermissionsAssert';
import {PermissionKeyFE_DeveloperViewer} from '@nu-art/permissions/frontend/core/permission-keys';
import {DBProto_User} from '@app/org/_entity/user/shared/types';
import {DispatcherType_User, ModuleFE_User} from '@app/org/_entity/user/frontend/ModuleFE_User';
import './Component_Header.scss';
import {ModuleFE_Artifact} from '@app/org/_entity/artifact/frontend/ModuleFE_Artifact';


type Props = {}
type State = {
	routeKey?: string
}

export class Component_Header
	extends ComponentSync<Props, State>
	implements ToggleSideNavBarStateListener, OnLocationChanged, DispatcherInterface<DispatcherType_Discussion>, DispatcherInterface<DispatcherType_User>, OnServerInfoUpdatedListener {
	__onServerInfoUpdated = () => {
		this.forceUpdate();
	};

	// ######################### Life Cycle #########################

	__onDiscussionsUpdated(...params: ApiCallerEventType<DBProto_Discussion>) {
		this.reDeriveState();

	}

	__onLocationChanged(path: string) {
		this.reDeriveState();
	}

	__onToggleSideNavBarState(): void {
		this.forceUpdate();
	}

	__onUsersUpdated = (...params: ApiCallerEventType<DBProto_User>) => {
		this.forceUpdate();
	};

	protected deriveStateFromProps(nextProps: Props): State {
		return {
			routeKey: ModuleFE_RoutingV2.getCurrentRouteKey()?.key,
		};
	}

	// ######################### Logic #########################

	private getHeaderPageTitle = () => {
		const routeDomainsDeclaration = RouteDomainsMap[this.state.routeKey!];
		if (!routeDomainsDeclaration)
			this.logError(`Could not find route title for route key ${this.state.routeKey}`);
		return routeDomainsDeclaration?.routeTitle || 'Title not implemented for this route';
	};

	private discussionsButton() {
		return <AwaitModules modules={[ModuleFE_Discussion] as ModuleFE_BaseDB<any>[]}>
			<Component_DiscussionButton/>
		</AwaitModules>;
	}

	private getEnvString() {
		switch (ModuleFE_AppModule.getEnv()?.toLowerCase()) {
			case 'local':
				return 'local';
			case 'dev':
				return 'dev';
			case 'staging':
				return 'staging';
			default:
				return '';
		}
	}

	private openAccountMenu = (e: React.MouseEvent) => {
		const user = ModuleFE_User.getUser()!;
		const menuActions: PopUp_AccountMenu_Action[] = [
			PopUp_AccountMenu.Action_EditPassword,
			{
				label: 'Edit My Details',
				type: 'page',
				pageKey: 'edit-my-details',
				content: (account, trigger) => <Component_UserDetailsEditor account={account}
																			onSubmitCompleted={trigger}/>
			},
		];
		if (ModuleFE_PermissionsAssert.getAccessLevel(PermissionKeyFE_DeveloperViewer) === AccessLevel.HasAccess)
			menuActions.push(PopUp_AccountMenu.Action_AppToolsButton('/app-tools'));
		if (ModuleFE_PermissionsAssert.getAccessLevel(ModuleFE_AppModule.permissions.ui.Allowed) === AccessLevel.HasAccess)
			menuActions.push({
				label: 'Create Artifact',
				// className: 'create-artifact-button',
				id: 'create-artifact-button',
				type: 'action',
				closePopUp: false,
				action: async () => {
					const buttonElement = (document.getElementById('create-artifact-button') as HTMLDivElement);
					try {
						const env = Thunder.getInstance().getConfig().label?.toLowerCase() === 'local' ? 'local' : Thunder.getInstance().getConfig().label?.toLowerCase();
						//Not awaiting in purpose.
						ModuleFE_Artifact._v1['trigger-artifact-pipeline']({
							fromEnv: env,
							toEnv: env,
						}).setOnCompleted(async response => {
							buttonElement.classList.remove('create-artifact-button-disabled');
							buttonElement.classList.add('create-artifact-button-enabled');
							ModuleFE_Dialog.show(<div className={'pop-up_trigger-artifact'}>
								{`Artifact creation ${response.triggered ? 'triggered' : 'failed triggering'}.`}
							</div>, () => true, 'artifact-trigger-message');
						}).setOnError(async errorResponse => {
							buttonElement.classList.remove('create-artifact-button-disabled');
							buttonElement.classList.add('create-artifact-button-enabled');
							ModuleFE_Dialog.show(<div className={'pop-up_trigger-artifact'}>
								{`Artifact creation failed triggering.`}
							</div>, () => true, 'artifact-trigger-message');
						}).executeSync();
					} finally {
						buttonElement.classList.remove('create-artifact-button-enabled');
						buttonElement.classList.add('create-artifact-button-disabled');
					}
				}
			});

		PopUp_AccountMenu.show(
			e,
			{
				accountId: user._id,
				accountDisplayModifier: (account) => {
					const user = ModuleFE_User.cache.unique(account._id);
					if (!user)
						return;

					let str = '';
					str += user.title ?? '';
					str += ` ${user.firstName ?? ''}`;
					str += ` ${user.surname ?? ''}`;
					return str.trim() || undefined;
				},
				acronymComposer: this.userAcronymComposer,
				menuActions: menuActions
			},
			{offset: {y: 20, x: 15}}
		);
	};

	private userAcronymComposer = (accountId: UniqueId): string | undefined => {
		const user = ModuleFE_User.cache.unique(accountId);
		if (!user || (!user.firstName || !user.surname))
			return;

		return user.firstName.charAt(0) + user.surname.charAt(0);
	};

	// ######################### Render #########################

	render() {
		const user = ModuleFE_User.getUser();
		const shouldUpdateVersion: boolean = ModuleFE_ServerInfo.Version.shouldUpdateVersion();
		const newVersion: string = ModuleFE_ServerInfo.Version.getLatestVersion();
		return (
			<div className={_className('knowledge-manager__header ll_h_c match_width', this.getEnvString())}>
				<LL_H_C className={'header__left-container flex__grow'}>
					<ICONS.sidebarMaximize.component
						className={_className('header__side-nav-bar__open-icon', ModuleFE_AppModule.getNavBarStatus() ? 'open' : undefined)}
						onClick={(e) => {
							ModuleFE_AppModule.toggleSideNavBar();
						}}/>
					<span
						className={'header__left-container__title'}>KNOWLEDGE MANAGER - <b>{this.getHeaderPageTitle()}</b></span>
				</LL_H_C>
				<LL_H_C className={'header__right-container flex__grow'}>
					{shouldUpdateVersion &&
                        <div className={'version-update-message'} onClick={() => this.updateVersion()}>
							{`Version ${newVersion} available. Click to update`}
                        </div>}
					<ICONS.notificationsBell.component className={'notifications-button'}
													   onClick={() => ModuleFE_Notifications.showAllNotifications()}/>
					{this.discussionsButton()}
					{user && <Component_AccountThumbnail
                        accountId={() => user._id}
                        onClick={this.openAccountMenu}
                        acronymComposer={this.userAcronymComposer}
                        modulesToAwait={[ModuleFE_User as unknown as ModuleFE_BaseApi<any>]}
                    />}
					<ICONS.quai.component className={'header__logo'}/>
				</LL_H_C>
			</div>
		);
	}

	private updateVersion = async () => {
		await ModuleFE_Account.logout();
		window.location.reload();
		await ModuleFE_StorageCleaner.cleanAll();
	};
}